import React from "react"
import News from "../../../blocks/en/news_page"
import { graphql } from "gatsby"

export const pageQuery = graphql`
query {
  news: newsYaml(slug: { eq: "ninua_first_goal" }) {
    ...NewsInfoEn
  }
}`

export default class NewsTextEn extends News {
  renderNews() {
    return (
      <div className="row">
        <div className="col-xl-12">
          <p>PAOK FC midfielder Nikoloz Ninua scored his first official goal for his new club. Nika came on as a substitute in the 82th minute of the Greece Super League game against FC Panetolikos and scored a perfect free kick on the 88th minute. The game ended with the score 3:1 in favor of PAOK FC. Congratulations to Nikoloz Ninua and we're looking forward to continuing!</p>
          <div className="video">
            <iframe
              src="https://www.youtube.com/embed/S1oY9sVIq0Q"
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
            />
          </div>
        </div>
      </div>
    )
  }
}